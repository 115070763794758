*{
  font-family: "Roboto", serif;
}

html,body {
  overflow-x: hidden;
}

h1 {
   font-size: 2.00rem; 
  
  }
h2 { font-size: 1.74rem; }
h3 { font-size: 1.52rem; }
h4 { font-size: 1.32rem; }
h5 { font-size: 1.15rem; }
h6 { font-size: 1.00rem; }

h1, h2, h3, h4,
h5, h6 { font-weight: bolder }

@media (max-width: 600px) {
  .line {
    display: none;
  }
}
@media (min-width: 1080px) {
  .footer-contact{
    margin-left: 12%;
  }

}
@media (max-width: 770px) {
  .footer-contact{
    margin-left: 60px;
  }
  .footer-location{
      text-align: center;
    margin: 0px;
    padding: 0px;
  }
  .quick-links{
    font-size: small;
  }
}
.container{
   max-width: 100%;
}

.app{
  height: 100vh;
   display: flex;
   flex-direction: column;
}

.body{
  flex-grow: 1;
}

.h-links{
   text-decoration: none;
   color: white;
   padding: 10px;
   margin:0px 15px;
   font-weight: bold;
}
.h-links:hover{
  text-decoration: none;
}

.hero-text{
   font-size: 1.5rem;
}
.school-logo-header{
  display: inline;
  object-fit: contain;
}
@media (min-width:1000px){
  .school-name-header{
    font-size: 1.9rem ;
  }
  .school-logo-header{
      height: 120px;
      width: 120px;
  }
}

.welcome-header{
  display: flex;
  flex-direction: column;
}
.welcome-message{
  padding: 3px;
  text-align: center;
 }
.header-icons{
  margin: 3px 50px;
  display: flex;
}
/* @media (min-width:1200px){
  .welcome-header{
     display: flex;
  }
}*/
.welcome-message{
  text-align: center;
 }
 .header-icons{
  justify-content: center;
}
 /* @media (max-width:1200px){
   .welcome-header{
      flex-direction: column;
   }
   .welcome-message{
    text-align: center;
   }
   .header-icons{
    justify-content: center;
  }
}  */

.hero-section{
  height: 700px;
  position: relative;

}

.hero-button{
  position: absolute;
}
@media (max-width:1000px){
  .school-name-header{
    font-size: 1.5rem ;
  }
  .school-logo-header{
    height: 120px;
    width: 120px;
  }
  .school-code{
    font-size: 1rem;
  }
}

@media (max-width:890px){
  .hero-text{
    font-size: 1.3rem;
  }
 .school-name-header{
     font-size: 1.2rem ;
  }
  .school-logo-header{
    height: 100px;
    width: 100px;
  }
  .hero-section{
    height: 600px;
   }
   .school-code{
    font-size: 0.6rem;
  }
}

@media (max-width:520px){
  .hero-text{
    font-size: 0.8rem;
 }
 .hero-section{
  height: 300px;
 }
}

@media (max-width:773px){
   .h-links{
     display: flex;
     justify-content: center;
     align-items: center;
     margin: 0px;
     padding: 5px 9px;
   }
   .nav-bar{
      width: 70%;
      justify-content: center;
   }
}

@media (max-width:590px){
  .nav-bar{
    width: 100%;
    justify-content: center;
 }
}




.slide-left-enter {
  animation: slide-left 0.5s forwards; /* Animation lasts 0.5 seconds */
}

@keyframes slide-left {
  0% {
    background-position: 100% 0; /* Start with the image off-screen to the right */
  }
  100% {
    background-position: 0 0; /* Move the image to its original position */
  }
}



.quick-links{
   background-color: #0368ED;
}

.quick-links a{
  color:white;
  text-decoration: none;
}

.links-black{
  text-decoration: none;
  color: rgb(81, 80, 80);
}

.nav-bar {
  display: flex;

}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px 0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-item {
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
  display: block;
}

.dropdown-item:hover {
  background-color: #f4f4f4;
}


/* 
.flip-card {
  background-color: transparent;
  perspective: 1000px;
}


.flip-card-inner {
  position: relative;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}


.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}


.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; 
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}


.flip-card-back {
  background-color: dodgerblue;
  color: white;
  transform: rotateY(180deg);
} */
.fee-structure {
  width: 60%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}
@media (max-width:1150px){
  .fee-structure {
    width: 97%;
  }
}
.table-container {
  overflow-x: auto; /* Enables horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on touch devices */
  margin-bottom: 20px;
  border: 1px solid #ddd; /* Optional border for the scroll container */
}

.fee-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px; /* Ensures the table doesn't shrink too much */
}

.fee-table th,
.fee-table td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 10px;
}

.header-cell {
  background-color: #002060; /* Dark blue */
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.section-header td {
  background-color: #404040; /* Gray */
  color: #ffffff;
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
}

.fee-table td {
  height: 50px;
}

.fee-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.fee-table tr:hover {
  background-color: #f1f1f1;
}


.table-head{
  background-color: #023db3;
  display: flex;
  justify-content: center;
  align-items:end ;
  height: 70px;
}

.table-option{
  margin: 0px 50px;
  color: white;
  background-color: #002060; /* Dark blue */
  padding:10px;
}

.table-option1{
  margin: 0px 5px;
  color: white;
  background-color: #002060; /* Dark blue */
  padding:10px;
}

@media (max-width:690px){
   .table-head{
     justify-content:space-around;
   }
   .table-option{
     margin: 0px;
     font-size: small;
   }
   .table-option1{
    margin: 0px 1px;
    color: white;
    background-color: #002060; /* Dark blue */
    padding:3px;
    font-size: small;
  }
}

.selected-table-option{
  color: black;
  background-color: #f1f1f1;
}

.fee-notes{
  text-align: justify;
}

.photo-container {
  position: relative;
  overflow: hidden;
  height: 400px;
}

@media (max-width:550px){
  .photo-container {
    height: 300px;
  }
  .overlay{
    font-size: small;
  }
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.photo-container:hover .photo {
  transform: scale(1.1); /* Zoom in effect */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  padding:20px;
  color: white;
  transition: opacity 0.5s ease;
}

.photo-container:hover .overlay {
  opacity: 1; /* Show overlay text on hover */
}

.core-value-text{
   width: 400px;
}

@media (max-width:765px){
  .core-value-text{
     width: 95%;
     margin-right: 10px;
  }
}


.icon-wrapper {
  width: 50px;
  height: 50px;
  background-color: #004080; /* Navy blue */
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
}

.card-title {
  margin-bottom: 10px;
}

p {
  margin: 0;
}
